import React from 'react';
import '../../App.css';
import Footer from '../Footer';

export default function About() {
  return (
    <>
      <h1 className='about'>About me!</h1>
      <img className='gif frog' src={require('../../assets/images/frog.GIF')} alt="frog!" />

      <hr />

      <div className='container letter-space'>
        <p> <br /> Hey! I am a mechatronics student at the University of Waterloo with a passion for humanoid robots + mobile robots in general. <br /> <br />
        
        I'm well experienced in C, C++, Python, and a lot more. I like working in embedded firmware and would love to explore perception software.

        I love rapid prototyping, CAD, electrical, and controls. I'm proficient in SolidWorks and Altium and believe in the value of diverse experiences.
           <br /> <br />

        Just trying to leave the world better than I found it.

        
       </p>
       <img className='headshot' src={require('../../assets/images/wrench.jpg')} alt="me!" />
      </div>
      

      

      <Footer />
    </>
  );
}